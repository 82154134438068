import { useInfiniteQuery, useQuery } from "react-query"
import api, { messengerApi } from ".."
import { TApiResponse } from "../../types/api"


type TProps = {
    endpoint: string,
    requestData?: { [key: string]: any },
    enabled?: boolean,
    refetchInterval?: number,

}

const useMessenger = <dataType = any>(props: TProps) => {
    const { endpoint, requestData = {}, enabled = true, refetchInterval = 0 } = props
    const fetchKey = ["messenger", endpoint, requestData]


    const { isLoading,
        isFetching,
        isRefetching,
        error,
        data,
        hasNextPage,
        refetch,
        fetchNextPage } = useInfiniteQuery(fetchKey, ({ pageParam = 1, signal }) => messengerApi<dataType>({ endpoint, data: { ...requestData, page: pageParam }, config: { signal } }), {
            retry: false,
            refetchOnWindowFocus: false,
            enabled,
            refetchInterval,
            getNextPageParam: (lastPage, pages) => {
                const pageCount = lastPage?.detail?.pages_count
                const currentPageCount = pages.length
                const nextPage = pageCount > currentPageCount ? currentPageCount + 1 : undefined
                return nextPage
            }
        })
    return {
        isLoading,
        isFetching,
        isRefetching,
        error,
        data,
        hasNextPage,
        refetch,
        fetchNextPage
    }
}

export default useMessenger