import { useMutation } from "react-query"
import api, { messengerApi } from ".."
import { getErrorToast, getSuccessToast } from "../../constructor/helpers/toasts"

type TProps = {
    endpoint: string,
    showToasts?: boolean | {success: boolean, error: boolean}
}
const useMessengerMutate = <item = any>(props: TProps) => {
    const {endpoint, showToasts = true} = props

    const isShowSuccessToasts = typeof showToasts === "object" ? showToasts.success : showToasts
    const isShowErrorToasts = typeof showToasts === "object" ? showToasts.error : showToasts

    const { mutate, mutateAsync, isSuccess, isLoading, data } = useMutation<any, any, item>(item => messengerApi({endpoint, data: item as {[key: string]: any} }), {
        onError: (error: any) => {
            if (isShowErrorToasts) {
                getErrorToast(error.message)
            }   
        },
        onSuccess: () => {
            if (isShowSuccessToasts) {
                getSuccessToast()
            }
        }
    })
    return { mutate, mutateAsync, isSuccess, isLoading, data }
}

export default useMessengerMutate