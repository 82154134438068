import React, { useEffect, useRef, useState } from "react"
import { KTSVG, toAbsoluteUrl } from "../../../../_metronic/helpers"
import clsx from "clsx"
import { TModuleMessengerChatObject, TModuleMessengerMessageObject, TModuleMessengerObject, TModuleMessengerToggleButton } from "./_types"
import moment from "moment"
import { Formik, Form as FormikForm } from "formik"
import ComponentSelect from "../../components/ComponentSelect"
import * as Yup from 'yup';
import { useIntl } from "react-intl"
import ComponentTextarea from "../../components/ComponentTextarea"
import ComponentButton from "../../components/ComponentButton"
import SplashScreen, { SplashSpinner } from "../../helpers/SplashScreen"
import useMessenger from "../../../api/hooks/useMessenger"
import { InfiniteScroll } from "../ModuleList/src/InfiniteScroll"
import useMessengerRequest from "../../../api/hooks/useMessengerRequest"
import useMessengerMutate from "../../../api/hooks/useMessengerMutate"

const itemClass = 'ms-1 ms-lg-3'
const btnClass =
    'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px'
const btnIconClass = 'svg-icon-1'

const ModuleMessengerToggleButton: React.FC<TModuleMessengerToggleButton> = ({ setShow }) => {
    return <div className={clsx('app-navbar-item position-relative', itemClass)}>
        <div className={clsx('position-relative', btnClass)} id='moduleDialog_toggle' onClick={() => setShow(prev => !prev)}>
            <KTSVG path='/media/crm/icons/whatsapp.svg' className={btnIconClass} />
        </div>
        {/*  {count?.total ? <span className="notifications_count">{count?.total}</span> : null} */}
    </div>
}

const validationSchema = Yup.object().shape({
    message: Yup.string().required(" ")
})


const ModuleMessenger = React.memo<{filter?: string}>(({filter}) => {
    const [show, setShow] = useState(false)
    const containerClass = `moduleMessenger_container${show ? " active" : ""} card`


    const [selectedMessenger, setSelectedMessenger] = useState<string | null>(null)
    const [selectedChat, setSelectedChat] = useState<TModuleMessengerChatObject | null>(null)
    const { data: messengers, isLoading: isMessengersLoading } = useMessengerRequest<Array<TModuleMessengerObject>>({endpoint: "/messengers", requestData: {filter: filter}, enabled: show})
    const {
        data: chats,
        isLoading: isChatsLoading,
        isFetching: isChatsFetching,
        hasNextPage: hasNextChatsPage,
        fetchNextPage: fetchNextChatsPage
    } = useMessenger<Array<TModuleMessengerChatObject>>({ endpoint: "/chats", requestData: { messenger: selectedMessenger }, enabled: Boolean(selectedMessenger), refetchInterval: 10000 })
    const { data: messages,
        isLoading: isMessagesLoading,
        isFetching: isMessagesFetching,
        hasNextPage: hasNextMessagesPage,
        fetchNextPage: fetchNextMessagesPage,
        refetch: refetchMessages
    } = useMessenger<Array<TModuleMessengerMessageObject>>({ endpoint: "/messages", requestData: { messenger: selectedMessenger, user_id: Number(selectedChat?.value) }, enabled: Boolean(selectedMessenger && selectedChat), refetchInterval: 5000 })
    const messagesContainerClass = `moduleMessenger_messagesContainer${selectedChat ? " active" : ""}`
    const messagesContainerRef = useRef<HTMLDivElement>(null)
    const { mutate: sendMessage, isLoading: isMessageSending, isSuccess } = useMessengerMutate<{ user_id: number, message: string, messenger: string }>({endpoint: "/send_message"})
    const intl = useIntl()
    useEffect(() => {
        if (Array.isArray(messengers) && messengers.length) {
            setSelectedMessenger(messengers[0].value as string)
        }
    }, [messengers])

    useEffect(() => {
        const container = messagesContainerRef.current
        if (container) {
            container.scrollTop = container.scrollHeight
        }
    }, [messages?.pages.length])

    useEffect(() => {
        if (isSuccess) {
            refetchMessages()
        }
    }, [isSuccess])

    const handleShow = (source: string) => {
        const link = document.createElement('a')
        link.href = source
        link.target = "_blank"
        document.body.appendChild(link)
        link.click()
        return link.remove()
    }

    const showTitleSpinner = (!isChatsLoading && isChatsFetching) || (!isMessagesLoading && isMessagesFetching)

    return <>
        <ModuleMessengerToggleButton setShow={setShow} />
        <div className={containerClass}>
            <div className="card-header">
                <div className={`moduleMessenger_spinnerContainer${showTitleSpinner ? " active" : ""}`}>
                    <SplashSpinner />
                </div>
                {selectedChat ? <h3 className="card-title">{selectedChat.title}</h3> :
                    <Formik enableReinitialize initialValues={{ current: selectedMessenger ?? undefined }}
                        onSubmit={(values) => setSelectedMessenger(values.current as string)}>
                        {() => <FormikForm>
                            <ComponentSelect
                                prefix="moduleMessenger_filter"
                                article="current"
                                data_type="string"
                                /*   placeholder={placeholder ?? title} */
                                list={messengers?.map(messenger => ({title: messenger.name, value: messenger.value})) ?? []}
                                isMulti={false}
                                onChangeSubmit
                                menuPortal={false}
                                isSearchable={false}
                            />

                        </FormikForm>}
                    </Formik>
                }
            </div>
            <div className="card-body">
                <div className="moduleMessenger_chatsContainer">
                    {isMessengersLoading || isChatsLoading ? <SplashScreen active /> : null}
                    {(!isMessengersLoading && !isChatsLoading && !chats?.pages.length) ? <div className="moduleMessenger_emptyList">Список пуст</div> : null}
                    <ul className="moduleMessenger_chatsList">
                        <li className="moduleMessenger_chatContainer">
                            {chats?.pages.length ?
                                chats.pages.reduce<Array<TModuleMessengerChatObject>>((acc, currentValue) => acc.concat(currentValue.data), [])
                                    .map(chat => <div key={chat.value} className="moduleMessenger_chat" onClick={() => setSelectedChat(chat)}>
                                        {chat.icon ? <img src={chat.icon} alt="" className="moduleMessenger_chatImage" onError={event => event.currentTarget.src = toAbsoluteUrl("/media/crm/assets/blank.png")} /> : null}

                                        <div className="moduleMessenger_chatProperties">
                                            <div className="moduleMessenger_chatTitle">
                                                {chat.title}
                                            </div>
                                            <div className="moduleMessenger_chatPreview">
                                                {chat.last_message}
                                            </div>
                                        </div>
                                    </div>) : null}

                        </li>
                    </ul>
                    <InfiniteScroll fetch={fetchNextChatsPage} isFetching={isChatsFetching} hasNextPage={hasNextChatsPage} showPagination={false} />
                </div>
                <div ref={messagesContainerRef} className={messagesContainerClass}>
                    {isMessagesLoading ? <SplashScreen active /> : null}
                    {(!isMessagesLoading && !messages?.pages.length) ? <div className="moduleMessenger_emptyList">Список пуст</div> : null}
                    <div className="moduleMessenger_messagesList">
                        {messages?.pages.length ?
                            messages.pages.reduce<Array<TModuleMessengerMessageObject>>((acc, currentValue) => acc.concat(currentValue.data), [])
                                .map(message => <div key={message.value} className={`moduleMessenger_messageContainer${message.income ? " income" : ""}`}>
                                    <div className="moduleMessenger_messageProperties">
                                        {message.attachments.length ? <ul className="moduleMessenger_attachmentsList">
                                            {message.attachments.map((attachment, index) => <li key={attachment}>
                                                <div className="moduleMessenger_attachment" onClick={() => handleShow(attachment)}>
                                                    <KTSVG path='/media/crm/icons/files.svg' />
                                                    <span className="moduleMessenger_atachmentProperties">
                                                        Вложение №{index + 1}
                                                    </span>
                                                </div>
                                            </li>)}
                                        </ul>
                                            : null}
                                        <div className="moduleMessenger_message">{message.message}</div>
                                        <div className="moduleMessenger_messageDate">{moment(message.timestamp).format("DD MMM HH:mm")}</div>
                                    </div>
                                </div>) : null}
                    </div>
                </div>
            </div>
            <div className={`card-footer${!selectedChat || isMessagesLoading ? " hidden" : ""}`}>
                <Formik
                    enableReinitialize
                    initialValues={{
                        user_id: Number(selectedChat?.value) as number,
                        messenger: selectedMessenger as string,
                        message: ""
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, { resetForm }) => {
                        sendMessage(values)
                        resetForm()
                    }}

                >
                    {({ handleSubmit }) => {
                        return <FormikForm>
                            <div className="moduleMessenger_messageForm">
                                <div className="moduleMessenger_textarea">
                                    <ComponentTextarea article="message" rows={3} />
                                </div>

                                <ComponentButton
                                    className="moduleMessenger_submitButton"
                                    type="submit"
                                    settings={{ title: intl.formatMessage({ id: "BUTTON.SEND" }), background: "secondary", icon: "message" }}
                                    customHandler={handleSubmit}
                                    defaultLabel="icon"
                                    loading={isMessageSending}
                                />
                            </div>
                        </FormikForm>
                    }}
                </Formik>
            </div>
            <button type="button" className={`moduleMessenger_closeButton${!show ? " hidden" : ""}`} onClick={() => setShow(false)}>
                <KTSVG path='/media/crm/icons/close_2.svg' />
            </button>
            <button type="button" className={`moduleMessenger_returnButton${!(show && selectedChat) ? " hidden" : ""}`} onClick={() => setSelectedChat(null)}>
                <KTSVG path='/media/crm/icons/arrow_3.svg' />
            </button>
        </div>
    </>


})

export default ModuleMessenger