import { useQuery } from "react-query"
import api, { messengerApi } from ".."
import { TApiResponse } from "../../types/api"
import { getErrorToast } from "../../constructor/helpers/toasts"


type TProps = {
    endpoint: string,
    requestData?: { [key: string]: any },
    enabled?: boolean,
    refetchInterval?: number,

}

const useMessengerRequest = <dataType = any>(props: TProps) => {
    const { endpoint, requestData = {}, enabled = true, refetchInterval = 0 } = props
    const fetchKey = ["messenger", endpoint, requestData]

    const fetchFunction = () => messengerApi({ endpoint, data: { filter: "community" } })

    const hookConfiguration = {
        retry: false,
        refetchOnWindowFocus: false,
        enabled,
        refetchInterval,
        select: (data: TApiResponse<dataType>) => data.data,
        /* временно убрать тосты, т.к. запрос на получение полей редактирования (List) работает только у RitZip */
        onError: (error: any) => { }/* getErrorToast(error.message) */,
    }
    const { isLoading, isFetching, error, data, refetch } = useQuery(fetchKey, fetchFunction, hookConfiguration)
    return { isLoading, isFetching, error, data, refetch }
}

export default useMessengerRequest