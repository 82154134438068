import { Modal } from "react-bootstrap"
import setModalIndex from "../../helpers/setModalIndex"
import { useIntl } from "react-intl"
import { useEffect, useState } from "react"
import ComponentButton from "../ComponentButton"

export type TComponentAttentionModal = {
    callback: (() => void) | null,
    description?: string,
    resetCallback: () => void
}

const ComponentAttentionModal: React.FC<TComponentAttentionModal> = props => {
    const { callback, resetCallback, description } = props
    const intl = useIntl()
    const [modalDescription, setModalDesciption] = useState(intl.formatMessage({ id: "MODAL.ATTENTION_DESCRIPTION" }))
    const [timeoutId, setTimeotId] = useState<NodeJS.Timeout | null>(null)

    useEffect(() => {
        if (description) {
            if (timeoutId) {
                clearTimeout(timeoutId)
            }
            setModalDesciption(description)
        }
    }, [description])

    const handleHide = () => {
        resetCallback()
        const id = setTimeout(() => setModalDesciption(intl.formatMessage({ id: "MODAL.ATTENTION_DESCRIPTION" })), 500)
        setTimeotId(id)
    }

    const handleAccept = () => {
        callback?.()
        handleHide()
    }

    return <Modal show={Boolean(callback)} onHide={handleHide} size="sm" centered onEntering={setModalIndex}>
        <Modal.Header>
            <Modal.Title>
                {intl.formatMessage({ id: "MODAL.ATTENTION_TITLE" })}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <b>{modalDescription}</b>
        </Modal.Body>
        <Modal.Footer>
            <ComponentButton
                type="custom"
                settings={{ title: intl.formatMessage({ id: "BUTTON.CANCEL" }), background: "light", icon: "" }}
                customHandler={handleHide}
            />
            <ComponentButton
                type="custom"
                settings={{ title: intl.formatMessage({ id: "BUTTON.SUBMIT" }), background: "dark", icon: "" }}
                customHandler={handleAccept} />
        </Modal.Footer>
    </Modal>
}


export default ComponentAttentionModal